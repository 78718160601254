<template>
  <div style="font-size: 16px;">
    <p>
    <p align="center">
      <span><b>天征语音模型用户服务协议</b></span>
    </p>
    <p align="left" >
      <span style="font-size: 20px;">欢迎使用天征语音模型！</span>
    </p>
    <p>
      <span><b>《天征语音模型用户服务协议》（</b></span><span>以下简称“本协议”）是您（以下也称为“用户”）与天征语音模型（以下简称“本公司”）之间关于用户下载、安装、使用、复制天征语音模型软件（以下简称“本软件”）的权利与义务的约定。</span>
    </p>
    <p>
      <span>当用户下载或安装本软件时，将会被提示是否同意接受本协议，请务必认真阅读和理解本协议中规定的所有权利和限制。除非您接受本协议所有条款，否则您无权下载、安装或使用本软件及其相关服务。您一旦安装、复制、下载、访问或以其它方式使用本软件产品，将视为对本协议的接受，即表示您同意接受本协议各项条款的约束。如果您不同意本协议中的任何条款，请不要安装、复制或使用本软件。</span>
    </p>
    <p>
      • <span><span><span>1.</span></span></span><span>权利声明</span>
    </p>
    <p>
      <span><span><span>1.1 </span></span></span><span>本软件的所有权和运营权归本公司所有；</span>
    </p>
    <p>
      <span><span><span>1.2</span></span></span><span>本软件的一切知识产权，以及与本软件相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图
饰、图像、图表、色彩、界面设计、版面框架、有关数据、附加程序、印刷材料或电子文档等均为本公司所有，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。</span>
    </p>
    <p>
      • <span><span><span>2.</span></span></span><span>许可范围</span>
    </p>
    <p>
      <span><span><span>2.1 </span></span></span><span>下载、安装和使用：本软件为免费软件，用户可以非商业性、无限制数量地下载、安装及使用本软件。如用户未获授权从事商业性复制、销售、安装等行为，致使本公司产生利益损失的，本公司有权要求用户承担违约责任，除非获得本协议第</span><span><span><span>3.4</span></span></span><span>规定的个别授权。</span>
    </p>
    <p>
      <span><span><span>2.2 </span></span></span><span>复制、分发和传播：用户可以非商业性、无限制数量地复制、分发和传播本软件产品。但必须保证每一份复制、分发和传播都是完整和真实的、未被修改或附加、插入任何代码与信息，包括所有有关本软件产品的软件、电子文档版权和商标，亦包括本协议。如用户在传播与复制过程中恶意改变软件产品内容致使我方或他人权益受损的，用户应当承担责任。</span>
    </p>
    <p>
      • <span><span><span>3.</span></span></span><span>权利限制</span>
    </p>
    <p>
      <span><span><span>3.1 </span></span></span><span>禁止反向工程、反向编译和反向汇编：用户不得对本软件产品进行反向工程（</span><span><span><span>Reverse
Engineer</span></span></span><span>）、反向编译（</span><span><span><span>Decompile</span></span></span><span>）或反向汇编（</span><span><span><span>Disassemble</span></span></span><span>），同时不得改动编译在程序文件内部的任何资源。除法律、法规明文规定允许上述活动外，用户必须遵守此协议限制。</span>
    </p>
    <p>
      <span><span><span>3.2 </span></span></span><span>组件分割：本软件产品是作为一个整体产品而被授予许可使用，用户不得将各个部分分开用于任何目的。</span>
    </p>
    <p>
      <span><span><span>3.3 </span></span></span><span>不得捆绑：在本协议项下对本软件的复制、分发和传播，用户不得捆绑、附加任何不属于本软件的产品或信息，除非获得第</span><span><span><span>3.4</span></span></span><span>规定的个别授权。</span>
    </p>
    <p>
      <span><span><span>3.4 </span></span></span><span>个别授权：如需进行商业性的销售、复制、分发，包括但不限于软件销售、预装、捆绑等，必须获得本公司的书面授权和许可。</span>
    </p>
    <p>
      <span><span><span>3.5 </span></span></span><span>保留权利：本协议未明示授权的其他一切权利仍归本公司所有，
用户使用其他权利时必须获得本公司的书面同意。</span>
    </p>
    <p>
      • <span><span><span>4.</span></span></span><span>用户使用须知</span>
    </p>
    <p>
      <span><span><span>4.1 </span></span></span><span>软件功能：本软件是基于</span><span><span><span>Android</span></span></span><span>（安卓）系统开发的一款软件，提供手机上安装的应用开启多个账户同时登录功能，同时多个账号可以生成音频功能。为提供适配的升级版本，在请求联网更新时，需要上传设备信息、系统软件信息、网络状态以及其他相关信息以便提供相应的功能服务。这些数据是本软件正常运行需要的基本信息，不包含任何用户的个人隐私数据，也不用于确定任何的个人身份。本公司收集的所有用户相关信息仅供本软件提供正常的基础功能和</span><span><span><span>VIP</span></span></span><span>会员服务使用，不存在任何泄露的风险，用户不得以其个人信息的泄露为由要求我方承担责任。</span>
    </p>
    <p>
      <span><span><span>4.2 </span></span></span><span>软件可使用的范围：</span>
    </p>
    <p>
      <span><span><span>4.2.1 </span></span></span><span>操作系统：本软件仅适用于其官方网站公布支持的操作系统（即</span><span><span><span>Android</span></span></span><span>安卓系统）及具体的手机型号，软件会随操作系统启动而自动运行，以便随时提供所有功能服务并减少响应时间。如果用户在安装本软件后因任何原因欲放弃使用，可使用</span><span><span><span>Android</span></span></span><span>（安卓）系统相应方式删除本软件。</span>
    </p>
    <p>
      <span><span><span>4.2.2 </span></span></span><span>硬件需求： </span><span><span><span>Android</span></span></span><span>（安卓）移动通讯终端设备（手机</span><span><span><span>, </span></span></span><span>平板电脑）</span>
    </p>
    <p>
      <span><span><span>4.2.3</span></span></span><span>用户通过分身应用的独立账号登录分身应用，用户在登录界面输入相应</span><span><span><span>ID</span></span></span><span>和密码后，此</span><span><span><span>ID</span></span></span><span>或密码会被递送到对应应用的服务器上，因此我们并不记录也无法记录用户的账号信息及对应密码。</span>
    </p>
    <p>
      <span><span><span>4.3 </span></span></span><span>本软件的开发与技术支持：本软件由本公司提供开发与产品技术支持。</span>
    </p>
    <p>
      <span><span><span>4.4 </span></span></span><span>软件的修改和升级：本公司保留随时为用户提供本软件的修改、升级版本的权利。由用户选择确定后，软件会进行升级更新，产生相应的数据流量费，由运营商收取，运营商收取的数据流量费用与本公司无关。本软件提供相关的产品服务，除此之外与本产品有关的设备及所需的费用均应用户自行负担。</span>
    </p>
    <p>
      <span><span><span>4.5 </span></span></span><span>产品集成：为了提供更多的功能扩展，本软件可能将相关的产品集成在用户主页界面，提供用户自主选择下载和安装使用。</span>
    </p>
    <p>
      <span><span><span>4.6 </span></span></span><span>应用服务：使用本软件所支持的，以及软件自身需要的服务方式，可由用户自行选择。</span>
    </p>
    <p>
      <span><span><span>4.6.1</span></span></span><span>一键休眠：用户可通过一键休眠功能停止所有正在运行的分身应用。</span>
    </p>
    <p>
      <span><span><span>4.6.2 </span></span></span><span>用户首次分身应用后，会申请在桌面添加快捷方式。在此过程中根据系统的</span><span><span><span>ROM</span></span></span><span>情况会弹窗申请权限。</span>
    </p>
    <p>
      <span><span><span>4.7 </span></span></span><span>用户使用规则：用户应在遵守法律及本协议的前提下使用本软件，用户对其自行发表、传输等的内容负全部责任，发布、传送、转载含有下列内容之一的信息：</span>
    </p>
    <p>
      <span><span><span>4.7.1</span></span></span><span>违反宪法确定的基本原则的；</span>
    </p>
    <p>
      <span><span><span>4.7.2</span></span></span><span>危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</span>
    </p>
    <p>
      <span><span><span>4.7.3</span></span></span><span>损害国家荣誉和利益的；</span>
    </p>
    <p>
      <span><span><span>4.7.4</span></span></span><span>煽动民族仇恨、民族歧视，破坏民族团结的；</span>
    </p>
    <p>
      <span><span><span>4.7.5</span></span></span><span>破坏国家宗教政策，宣扬邪教和封建迷信的；</span>
    </p>
    <p>
      <span><span><span>4.7.6</span></span></span><span>散步谣言，扰乱社会秩序，破坏社会稳定的；</span>
    </p>
    <p>
      <span><span><span>4.7.7</span></span></span><span>散步淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</span>
    </p>
    <p>
      <span><span><span>4.7.8</span></span></span><span>侮辱或者诽谤他人，侵害他人合法权益的；</span>
    </p>
    <p>
      <span><span><span>4.7.9</span></span></span><span>煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</span>
    </p>
    <p>
      <span><span><span>4.7.10</span></span></span><span>以非法民间组织名义活动的；</span>
    </p>
    <p>
      <span><span><span>4.7.11</span></span></span><span>不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论。</span>
    </p>
    <p>
      <span><span><span>4.7.12</span></span></span><span>含有法律、行政法规禁止的其他内容的。</span>
    </p>
    <p>
      <span>若用户在使用过程中存在违反上述规定的行为，致使我方产生损失的，我方有权要求用户赔偿损失，包括但不限于财产损失和名誉损失等。</span>
    </p>
    <p>
      <span><span><span>4.8 </span></span></span><span>用户权利限制：用户应遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释</span><span><span><span>[</span></span></span><span>法释</span><span><span><span>1</span></span></span><span>号</span><span><span><span>]</span></span></span><span>》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、规定等及本协议的前提下使用本软件。如因用户违反下列规定致使我方或第三方产生利益损失的，用户须承担赔偿责任。用户违反国家相关法律规定，应由用户自行承担承担法律责任，（包括但不限于下列行为）：</span>
    </p>
    <p>
      <span><span><span>4.8.1 </span></span></span><span>不得删除或者改变本软件上的所有权利管理电子信息；</span>
    </p>
    <p>
      <span><span><span>4.8.2 </span></span></span><span>不得故意避开或者破坏著作权人为保护本软件著作权而采取的技术措施；</span>
    </p>
    <p>
      <span><span><span>4.8.3 </span></span></span><span>用户不得利用本软件误导、欺骗他人；</span>
    </p>
    <p>
      <span><span><span>4.8.4 </span></span></span><span>违反国家规定，对计算机信息系统功能进行删除、修改、增加、干扰，造成计算机信息系统不能正常运行；</span>
    </p>
    <p>
      <span><span><span>4.8.5 </span></span></span><span>未经允许，进入计算机信息网络或者使用计算机信息网络资源；</span>
    </p>
    <p>
      <span><span><span>4.8.6 </span></span></span><span>未经允许，对计算机信息网络功能进行删除、修改或者增加的；</span>
    </p>
    <p>
      <span><span><span>4.8.7 </span></span></span><span>未经允许，对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；</span>
    </p>
    <p>
      <span><span><span>4.8.8 </span></span></span><span>破坏本软件系统或网站的正常运行，故意传播计算机病毒等破坏性程序；</span>
    </p>
    <p>
      <span><span><span>4.8.9 </span></span></span><span>其他任何危害计算机信息网络安全的行为。</span>
    </p>
    <p>
      <span><span><span>4.9 </span></span></span><span>用户体验改善计划：为了改善用户体验、完善服务内容，本软件会根据需要对产品的使用情况进行统计，这些统计一般为使用某个功能的使用次数等相关信息（不包含用户的个人隐私信息）。主要用途通过分析数据提高产品质量，以便推出更好的产品体验。上述收集的数据，我们只用于改进我们的产品，不会提供给任何第三方或用于任何其他商业用户。我方收集的数据严格按照我国相关标准进行储存，不存在任何泄露相关信息的风险，用户不得以以其个人数据信息的泄露为由要求我方承担责任。</span>
    </p>
    <p>
      <span><span><span>4.10 </span></span></span><span>本软件下载：对于从非本公司指定站点下载的本软件产品以及从非本公司发行的介质上获得的本软件产品，本公司无法保证该软件是否感染病毒、是否隐藏有伪装的特洛伊木马程序或者黑客软件，使用此类软件，将可能导致不可预测的风险，建议用户通过本软件官网及本公司指定站点下载使用本软件铲平，因用户自身原因进行致使利益受损的，本公司不承担因此产生的一切法律责任。</span>
    </p>
    <p>
      <span><span><span>4.11 </span></span></span><span>程序错误日志上报：程序出现意外错误或崩溃时，会自动生成一分简短的错误日志，是本软件在出现错误或崩溃时，系统记录的运行信息，本软件根据用户同意的云服务选项，自动向本软件服务端上报错误日志，以便定位程序错误或崩溃的原因，改善产品质量。在用户同意及主动操作下，本软件可以生成更详细的错误报告，包含本软件所有自身数据及系统的日志输出、系统信息，详细错误信息文件会保存在用户本地存储中。本软件会询问用户是否上报错误日志，如果用户同意上报，本软件会将存储在本地的错误日志文件上报给本软件服务端；如果用户不同意，则不会上传。（该错误日志仅包含程序本身的出错信息，绝不涉及任何用户隐私数据）</span>
    </p>
    <p>
      • <span><span><span>5.</span></span></span><span>广告服务</span>
    </p>
    <p>
      <span><span><span>5.1 </span></span></span><span>广告服务是本软件的基础功能服务之一，是本软件不可拆分的一部分。您同意本公司可以在提供基本软件服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。</span>
    </p>
    <p>
      <span><span><span>5.2 </span></span></span><span>广告服务需要获取您的基本个人信息，包括且不限于设备信息、软件信息、设备上的应用使用数据、位置信息、网络状态等；在必要的时候，前述基本个人信息需要同第三方广告商共享。我们和第三方广告商承诺此信息仅用于广告的精准投放，不用于其他商业目的和非法用途。</span>
    </p>
    <p>
      <span><span><span>5.3 </span></span></span><span>本公司可能为您提供选择关闭广告信息的功能，但任何时候您都不得以本协议未明确约定或本公司未书面许可的方式屏蔽、过滤广告信息。</span>
    </p>
    <p>
      <span><span><span>5.4 </span></span></span><span>本公司依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，本公司不承担任何责任。</span>
    </p>
    <p>
      <span><span><span>5.5 </span></span></span><span>对本软件中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易承担全部责任。</span>
    </p>
    <p>
      • <span><span><span>6.</span></span></span><span>免责与责任限制</span>
    </p>
    <p>
      <span>作为用户，您需要了解以下事实以及科学技术进步可能导致的产品更新、以及不同用户的使用习惯、行为方式都可能导致责任情形出现。本公司或者本软件对于以下情形，在本协议中并没有给出任何担保，也不承担任何责任：</span>
    </p>
    <p>
      <span><span><span>6.1 </span></span></span><span>本协议中描述的软件功能可能存在的任何缺陷或瑕疵；用户应自行承担使用本软件任何功能所产生的风险，包括但不限于直接或间接损害。</span>
    </p>
    <p>
      <span><span><span>6.2 </span></span></span><span>用户基于本软件的扫描或检查结果或广告内容，导致与任何第三方就手机软件安装或者捆绑、吸费等纠纷的情形；本软件并不以此为目的而设计，也没有建议用户如此应用。若用户在使用过程出现上述行为及损失后果，用户不得以此为由主张本公司承担赔偿责任。</span>
    </p>
    <p>
      <span><span><span>6.3</span></span></span><span>用户同意保障和维护本公司及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给本公司或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</span>
    </p>
    <p>
      <span><span><span>6.4 </span></span></span><span>本公司不保证可以发现或者改正本软件的所有缺陷，亦不保证本软件能满足用户的所有需求。用户不能因本软件无法满足自己的需求为由追究本公司的责任。</span>
    </p>
    <p>
      <span><span><span>6.5 </span></span></span><span>任何因为技术障碍、法律法规变化或者有约束力的行政行为、不可抗力出现等导致的情形，本公司不承担任何责任，用户不得主张本公司的违约责任。</span>
    </p>
    <p>
      <span><span><span>6.6 </span></span></span><span>涉及第三方开发者权益：若其他第三方开发者或公司认为本软件对其有任何侵权或影响行为，可及时联系本公司进行删除或停止相关服务。本公司及时配合并降低影响的，本公司对前述行为不承担相关责任。</span>
    </p>
    <p>
      <span><span><span>6.7</span></span></span><span>结束服务：鉴于网络服务的特殊性，用户同意本公司有权随时变更、中断、或者终止部分或者全部的本软件提供的服务。若其他第三方认为本软件涉及其自身权益或者产品正常运行，可联系本软件工作人员进行处理，本公司可随时根据实际情况中断一项多项软件服务。本公司不需要对任何其他第三方或个人负责。</span>
    </p>
    <p>
      • <span><span><span>7.</span></span></span><span>法律及争议解决</span>
    </p>
    <p>
      <span><span><span>7.1 </span></span></span><span>本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国司法管辖。</span>
    </p>
    <p>
      <span><span><span>7.2 </span></span></span><span>因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；协商不成的，任何一方可向北京市海淀区人民法院提起诉讼。</span>
    </p>
    <p>
      • <span><span><span>8.</span></span></span><span>其他条款</span>
    </p>
    <p>
      <span><span><span>8.1 </span></span></span><span>本协议是用户和本公司之间关于本软件相关事项的最终的、完整的、排他的协议。</span>
    </p>
    <p>
      <span><span><span>8.2 </span></span></span><span>如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力，双方应当就其他有效的条款继续履行。</span>
    </p>
    <p>
      <span><span><span>8.3 </span></span></span><span>本公司有权随时根据有关法律、法规的变化、技术进步或者技术路线选择，以及公司经营状况和经营策略的调整等修改本协议。修改后的协议会在本软件的官方网站上公布，并随附于新版本软件。当发生有关争议时，以最新的协议文本为准。如果不同意改动的内容，用户可以自行删除本软件并不再安装使用。如果用户继续使用本软件，则视为用户接受本协议的变动，继续使用的用户不得因协议的变更而追究本公司的责任。</span>
    </p>
    <p>
      <span><span><span>8.4 </span></span></span><span>每部分的题目只为阅读之便，而不能应用于对条款内容的解释。</span>
    </p>
    <p>
      <span><span><span>8.5 </span></span></span><span>本公司在法律允许的最大范围内对本协议拥有解释权与修改权。</span>
    </p>
  </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.box{
  font-size: 12px;
}
</style>